import { DestroyRef, effect, inject, signal, untracked, WritableSignal } from "@angular/core";
import { Filter, FilterState, PillTemplateContext } from "../../models";
import { CustomParam, FiltersService } from "../../services";
import { Subject } from "rxjs";

export class FilterBaseHandler<T extends FilterState> {
  state: WritableSignal<T | undefined> = signal(undefined);
  private initialState: T = {} as T;
  filter?: Filter;

  inputValidator: (state?: T) => boolean;
  createHttpParam: () => CustomParam | undefined;
  templateContext?: () => PillTemplateContext | undefined;

  filterService = inject(FiltersService);

  readonly filterCleared$ = new Subject<T>();

  constructor(
    inputValidator: (state?: T) => boolean,
    createHttpParam: () => CustomParam | undefined,
    templateContext?: () => PillTemplateContext | undefined
  ) {
    this.inputValidator = inputValidator;
    this.createHttpParam = createHttpParam;
    this.templateContext = templateContext;

    effect(() => {
      const state = this.state();

      untracked(() => {
        this.updateValueStore(state);
      });
    });

    const clearSub = this.filterService.clearAllFilters$.subscribe(() => {
      this.filterCleared$.next(this.initialState);
      this.state.set(this.initialState);
    });

    inject(DestroyRef).onDestroy(() => {
      clearSub.unsubscribe();
    });
  }

  private updateValueStore(state: T | undefined): void {
    if (this.filter === undefined) {
      return;
    }

    if (state === undefined) {
      this.filterService.clearFilterValue(this.filter.id);
      return;
    }

    const newState = {
      ...state,
      isStateValid: this.inputValidator(state),
    };

    if (this.templateContext) {
      newState.templateContext = this.templateContext();
    }

    this.filterService.updateFilterValue(this.filter.id, newState, this.createHttpParam);
  }

  initialize(initialValue: T, filter: Filter): void {
    this.state.set(initialValue);
    this.initialState = structuredClone(initialValue);
    this.filter = filter;
  }

  updateState(update: Partial<T>): void {
    this.state.update((state) => {
      if (state === undefined) {
        return;
      }

      return { ...state, ...update };
    });
  }
}
