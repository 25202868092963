import { computed, DestroyRef, Injectable, signal } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { PageEvent } from "@angular/material/paginator";
import { MatDrawer } from "@angular/material/sidenav";
import { finalize, merge, Observable, ReplaySubject, switchMap, tap } from "rxjs";
import { CountedValues, Recording } from "@shared/models";
import { GridApiService } from "./grid-api.service";
import { ApplyFilterData, FiltersService } from "../../activity-filter/services";
import { PillTemplateContext } from "../../activity-filter/models";
import { RecordsRequestParams } from "../../models";

export enum GridState {
  Loading,
  Empty,
  Data,
  NoActiveFilters,
}

@Injectable()
export class GridService {
  static readonly EmptyCollection: CountedValues<Recording> = { values: [], totalCount: 0 };

  private update$ = new ReplaySubject<ApplyFilterData>(1);
  private collection = signal<CountedValues<Recording>>(GridService.EmptyCollection);
  private _isLoading = signal<boolean>(false);
  private queryParams = signal<RecordsRequestParams>({
    sort: {
      direction: "desc",
      key: "callStart",
    },
    pagination: { page: 0, pageSize: 25 },
    filters: undefined,
  });

  filterDrawer?: MatDrawer;
  records = this.collection.asReadonly();
  templateContexts = signal<PillTemplateContext[]>([]);
  gridState = computed<GridState>(() => {
    if (this._isLoading()) {
      return GridState.Loading;
    }

    if (!this.queryParams().filters?.size) {
      return GridState.NoActiveFilters;
    }

    if (this.records().values.length === 0) {
      return GridState.Empty;
    }

    return GridState.Data;
  });

  constructor(
    private apiService: GridApiService,
    private filterService: FiltersService,
    private destroyRef: DestroyRef
  ) {
    const filtersAndDataSub = merge(this.filterService.currentlyAppliedFilters$, this.update$)
      .pipe(
        tap(() => this._isLoading.set(true)),
        switchMap(() =>
          this.filterService.currentlyAppliedFilters$.pipe(
            tap((state) => this.applyFilters(state)),
            tap((state) => this.templateContexts.set(state.templateContexts)),
            switchMap(() => this.fetchData())
          )
        )
      )
      .subscribe((records) => this.collection.set(records));

    this.destroyRef.onDestroy(() => filtersAndDataSub.unsubscribe());
  }

  private applyFilters(state: ApplyFilterData): void {
    this.queryParams.update((params) => ({ ...params, filters: state.filters }));
  }

  private fetchData(): Observable<CountedValues<Recording>> {
    return this.apiService.fetchRecords(this.queryParams()).pipe(
      finalize(() => {
        this._isLoading.set(false);
      })
    );
  }

  refreshData(): void {
    // this.update$.next();
  }

  updateSort(sort: Sort): void {
    this.queryParams.update((params) => ({
      ...params,
      sort: { key: sort.active, direction: sort.direction },
    }));

    this.refreshData();
  }

  updatePagination(pageEvent: PageEvent): void {
    this.queryParams.update((params) => ({
      ...params,
      pagination: { page: pageEvent.pageIndex, pageSize: pageEvent.pageSize },
    }));

    this.filterService.applyFilters();
  }
}
