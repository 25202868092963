import { AfterViewInit, Component, computed, inject, viewChild } from "@angular/core";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { CdkTrapFocus } from "@angular/cdk/a11y";
import { ActivityGridComponent } from "./activity-grid/activity-grid.component";
import { FiltersService } from "./activity-filter/services/filters.service";
import {
  GridApiService,
  GridSelectionService,
  GridService,
  GridState,
} from "./activity-grid/services";
import { ActivityFilterComponent } from "./activity-filter/activity-filter.component";
import { NoActiveFiltersComponent } from "./no-active-filters/no-active-filters.component";
import { FilterValueService } from "./activity-filter/services/filter-value.service";
import { ActivityHeaderComponent } from "./activity-grid/activity-header/activity-header.component";

@Component({
  imports: [
    ActivityGridComponent,
    MatSidenavModule,
    ActivityFilterComponent,
    NoActiveFiltersComponent,
    CdkTrapFocus,
    ActivityHeaderComponent,
  ],
  providers: [
    FiltersService,
    FilterValueService,
    GridSelectionService,
    GridService,
    GridApiService,
  ],
  templateUrl: "./activity.component.html",
  styleUrl: "./activity.component.scss",
})
export class ActivityComponent implements AfterViewInit {
  private drawer = viewChild.required(MatDrawer);
  private gridService = inject(GridService);

  displayGrid = computed(() => this.gridService.gridState() !== GridState.NoActiveFilters);

  ngAfterViewInit(): void {
    this.gridService.filterDrawer = this.drawer();

    // Uncomment for easier filter dev-workflow
    // setTimeout(() => {
    //   this.openFilterDrawer();
    // }, 1);
  }

  openFilterDrawer(): void {
    void this.gridService.filterDrawer?.open();
  }
}
