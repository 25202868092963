import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { CountedValues, Recording } from "@shared/models";
import { RecordsRequestParams } from "@src/activity/models";
import { RecordingsResponse } from "../models";
import { API_BASE_URL } from "@shared/constants";
import { mapRecording } from "@shared/helpers";

@Injectable()
export class GridApiService {
  private http = inject(HttpClient);

  fetchRecords(queryParams: RecordsRequestParams): Observable<CountedValues<Recording>> {
    const skip = queryParams.pagination.page * queryParams.pagination.pageSize;
    const take = queryParams.pagination.pageSize;

    let params = new HttpParams({
      fromObject: {
        Skip: skip,
        Take: take,
        OrderBy: "Interval",
        Direction: queryParams.sort.direction,
      },
    });

    queryParams.filters?.forEach((filter) => {
      const param = filter.createHttpParam();
      if (param) {
        params = params.appendAll({ [param.key]: param.values });
      }
    });

    return this.http
      .get<CountedValues<RecordingsResponse>>(`${API_BASE_URL}/recordings`, {
        params,
      })
      .pipe(
        map((countedRecordings) => {
          const mappedRecordings = countedRecordings.values.map(mapRecording);

          return {
            values: mappedRecordings,
            totalCount: countedRecordings.totalCount,
          };
        })
      );
  }
}
