import { Injectable, Inject } from "@angular/core";
import { Location, DOCUMENT } from "@angular/common";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import {
  AccountInfo,
  AuthenticationResult,
  BrowserConfigurationAuthError,
  InteractionStatus,
  InteractionType,
  StringUtils,
} from "@azure/msal-browser";
import { Observable, EMPTY, of } from "rxjs";
import { switchMap, catchError, take, filter } from "rxjs/operators";
import {
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalInterceptorAuthRequest,
  MsalInterceptorConfiguration,
  MsalService,
  ProtectedResourceScopes,
} from "@azure/msal-angular";

/* 
  This class is identical to the MsalInterceptor class from the @azure/msal-angular package, with one major exception:
  The ID Token is used as the Authorization header instead of the Access Token.
*/

@Injectable()
export class CustomMsalInterceptor implements HttpInterceptor {
  private _document?: Document;

  constructor(
    @Inject(MSAL_INTERCEPTOR_CONFIG)
    private msalInterceptorConfig: MsalInterceptorConfiguration,
    private authService: MsalService,
    private location: Location,
    private msalBroadcastService: MsalBroadcastService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(DOCUMENT) document?: any
  ) {
    this._document = document as Document;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      this.msalInterceptorConfig.interactionType !== InteractionType.Popup &&
      this.msalInterceptorConfig.interactionType !== InteractionType.Redirect
    ) {
      throw new BrowserConfigurationAuthError(
        "invalid_interaction_type",
        "Invalid interaction type provided to MSAL Interceptor. InteractionType.Popup, InteractionType.Redirect must be provided in the msalInterceptorConfiguration"
      );
    }

    this.authService.getLogger().verbose("MSAL Interceptor activated");
    const scopes = this.getScopesForEndpoint(req.url, req.method);

    // If no scopes for endpoint, does not acquire token
    if (!scopes || scopes.length === 0) {
      this.authService.getLogger().verbose("Interceptor - no scopes for endpoint");
      return next.handle(req);
    }

    // Sets account as active account or first account
    let account: AccountInfo;
    if (this.authService.instance.getActiveAccount()) {
      this.authService.getLogger().verbose("Interceptor - active account selected");
      account = this.authService.instance.getActiveAccount()!;
    } else {
      this.authService
        .getLogger()
        .verbose("Interceptor - no active account, fallback to first account");
      account = this.authService.instance.getAllAccounts()[0];
    }

    const authRequest =
      typeof this.msalInterceptorConfig.authRequest === "function"
        ? this.msalInterceptorConfig.authRequest(this.authService, req, {
            account: account,
          })
        : { ...this.msalInterceptorConfig.authRequest, account };

    this.authService.getLogger().info(`Interceptor - ${scopes.length} scopes found for endpoint`);
    this.authService
      .getLogger()
      .infoPii(`Interceptor - [${scopes.length}] scopes found for ${req.url}`);

    return this.acquireToken(authRequest, scopes, account).pipe(
      switchMap((result: AuthenticationResult) => {
        this.authService.getLogger().verbose("Interceptor - setting authorization headers");

        // Add Id Token as Authorization header instead of the default Access Token
        const headers = req.headers.set("Authorization", `Bearer ${result.idToken}`);

        const requestClone = req.clone({ headers });
        return next.handle(requestClone);
      })
    );
  }

  /**
   * Try to acquire token silently. Invoke interaction if acquireTokenSilent rejected with error or resolved with null access token
   * @param authRequest Request
   * @param scopes Array of scopes for the request
   * @param account Account
   * @returns Authentication result
   */
  private acquireToken(
    authRequest: MsalInterceptorAuthRequest,
    scopes: string[],
    account: AccountInfo
  ): Observable<AuthenticationResult> {
    // Note: For MSA accounts, include openid scope when calling acquireTokenSilent to return idToken
    return this.authService.acquireTokenSilent({ ...authRequest, scopes, account }).pipe(
      catchError(() => {
        this.authService
          .getLogger()
          .error(
            "Interceptor - acquireTokenSilent rejected with error. Invoking interaction to resolve."
          );
        return this.msalBroadcastService.inProgress$.pipe(
          take(1),
          switchMap((status: InteractionStatus) => {
            if (status === InteractionStatus.None) {
              return this.acquireTokenInteractively(authRequest, scopes);
            }

            return this.msalBroadcastService.inProgress$.pipe(
              filter((status: InteractionStatus) => status === InteractionStatus.None),
              take(1),
              switchMap(() => this.acquireToken(authRequest, scopes, account))
            );
          })
        );
      }),
      switchMap((result: AuthenticationResult) => {
        if (!result.accessToken) {
          this.authService
            .getLogger()
            .error(
              "Interceptor - acquireTokenSilent resolved with null access token. Known issue with B2C tenants, invoking interaction to resolve."
            );
          return this.msalBroadcastService.inProgress$.pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            take(1),
            switchMap(() => this.acquireTokenInteractively(authRequest, scopes))
          );
        }
        return of(result);
      })
    );
  }

  /**
   * Invoke interaction for the given set of scopes
   * @param authRequest Request
   * @param scopes Array of scopes for the request
   * @returns Result from the interactive request
   */
  private acquireTokenInteractively(
    authRequest: MsalInterceptorAuthRequest,
    scopes: string[]
  ): Observable<AuthenticationResult> {
    if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
      this.authService
        .getLogger()
        .verbose("Interceptor - error acquiring token silently, acquiring by popup");
      return this.authService.acquireTokenPopup({ ...authRequest, scopes });
    }
    this.authService
      .getLogger()
      .verbose("Interceptor - error acquiring token silently, acquiring by redirect");
    const redirectStartPage = window.location.href;
    this.authService.acquireTokenRedirect({
      ...authRequest,
      scopes,
      redirectStartPage,
    });
    return EMPTY;
  }

  /**
   * Looks up the scopes for the given endpoint from the protectedResourceMap
   * @param endpoint Url of the request
   * @param httpMethod Http method of the request
   * @returns Array of scopes, or null if not found
   *
   */
  private getScopesForEndpoint(endpoint: string, httpMethod: string): Array<string> | null {
    this.authService.getLogger().verbose("Interceptor - getting scopes for endpoint");

    // Ensures endpoints and protected resources compared are normalized
    const normalizedEndpoint = this.location.normalize(endpoint);

    const protectedResourcesArray = Array.from(
      this.msalInterceptorConfig.protectedResourceMap.keys()
    );

    const matchingProtectedResources = this.matchResourcesToEndpoint(
      protectedResourcesArray,
      normalizedEndpoint
    );

    if (matchingProtectedResources.length > 0) {
      return this.matchScopesToEndpoint(
        this.msalInterceptorConfig.protectedResourceMap,
        matchingProtectedResources,
        httpMethod
      );
    }

    return null;
  }

  /**
   * Finds resource endpoints that match request endpoint
   * @param protectedResourcesEndpoints
   * @param endpoint
   * @returns
   */
  private matchResourcesToEndpoint(
    protectedResourcesEndpoints: string[],
    endpoint: string
  ): Array<string> {
    const matchingResources: Array<string> = [];

    protectedResourcesEndpoints.forEach((key) => {
      const normalizedKey = this.location.normalize(key);

      // Get url components
      const absoluteKey = this.getAbsoluteUrl(normalizedKey);
      const keyComponents = new URL(absoluteKey);
      const absoluteEndpoint = this.getAbsoluteUrl(endpoint);
      const endpointComponents = new URL(absoluteEndpoint);

      if (this.checkUrlComponents(keyComponents, endpointComponents)) {
        matchingResources.push(key);
      }
    });

    return matchingResources;
  }

  /**
   * Compares URL segments between key and endpoint
   * @param key
   * @param endpoint
   * @returns
   */
  private checkUrlComponents(keyComponents: URL, endpointComponents: URL): boolean {
    // URL properties from https://developer.mozilla.org/en-US/docs/Web/API/URL
    const urlProperties = ["protocol", "host", "pathname", "search", "hash"];

    for (const property of urlProperties) {
      if (keyComponents[property as keyof URL]) {
        const decodedInput = decodeURIComponent(keyComponents[property as keyof URL] as string);
        if (
          !StringUtils.matchPattern(
            decodedInput,
            endpointComponents[property as keyof URL] as string
          )
        ) {
          return false;
        }
      }
    }

    return true;
  }

  /**
   * Transforms relative urls to absolute urls
   * @param url
   * @returns
   */
  private getAbsoluteUrl(url: string): string {
    const link = this._document?.createElement("a");

    if (!link) {
      this.authService
        .getLogger()
        .error("Interceptor - Unable to get absolute URL. Returning input URL.");

      return url;
    }

    link.href = url;
    return link.href;
  }

  /**
   * Finds scopes from first matching endpoint with HTTP method that matches request
   * @param protectedResourceMap Protected resource map
   * @param endpointArray Array of resources that match request endpoint
   * @param httpMethod Http method of the request
   * @returns
   */
  private matchScopesToEndpoint(
    protectedResourceMap: Map<string, Array<string | ProtectedResourceScopes> | null>,
    endpointArray: string[],
    httpMethod: string
  ): Array<string> | null {
    const allMatchedScopes: (string[] | null)[] = [];

    // Check each matched endpoint for matching HttpMethod and scopes
    endpointArray.forEach((matchedEndpoint) => {
      const scopesForEndpoint: string[] = [];
      const methodAndScopesArray = protectedResourceMap.get(matchedEndpoint);

      // Return if resource is unprotected
      if (methodAndScopesArray == undefined) {
        allMatchedScopes.push(null);
        return;
      }

      methodAndScopesArray.forEach((entry) => {
        // Entry is either array of scopes or ProtectedResourceScopes object
        if (typeof entry === "string") {
          scopesForEndpoint.push(entry);
        } else {
          // Ensure methods being compared are normalized
          const normalizedRequestMethod = httpMethod.toLowerCase();
          const normalizedResourceMethod = entry.httpMethod.toLowerCase();
          // Method in protectedResourceMap matches request http method
          if (normalizedResourceMethod === normalizedRequestMethod) {
            // Validate if scopes comes null to unprotect the resource in a certain http method
            if (entry.scopes === null) {
              allMatchedScopes.push(null);
            } else {
              entry.scopes.forEach((scope) => {
                scopesForEndpoint.push(scope);
              });
            }
          }
        }
      });

      // Only add to all scopes if scopes for endpoint and method is found
      if (scopesForEndpoint.length > 0) {
        allMatchedScopes.push(scopesForEndpoint);
      }
    });

    if (allMatchedScopes.length > 0) {
      if (allMatchedScopes.length > 1) {
        this.authService
          .getLogger()
          .warning("Interceptor - More than 1 matching scopes for endpoint found.");
      }
      // Returns scopes for first matching endpoint
      return allMatchedScopes[0];
    }

    return null;
  }
}
