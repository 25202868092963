<ng-template #pillTemplate let-details="details">
  <span>{{ details }}</span>
</ng-template>

<ath-filter-base [filter]="filter()">
  <form [formGroup]="form" class="record-type-filter">
    @for (type of form.controls.types.controls; track type.controls.value.value) {
      <mat-checkbox [formControl]="type.controls.checked">
        {{ type.controls.value.value }}
      </mat-checkbox>
    } @empty {
      <span>No recording types available</span>
    }
  </form>

  <ng-template athFilterErrorContent>
    <span>Please select at least one record type</span>
  </ng-template>
</ath-filter-base>
