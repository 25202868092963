<h1>Recordings</h1>

<p class="secondary-title">
  @if (searchStatusText(); as text) {
    {{ text }}
  }
</p>

<mat-chip-set aria-label="Active filters">
  @for (tc of templateContexts(); track $index) {
    <mat-chip disableRipple matTooltip="Edit filter" (click)="openFilterDrawer(tc)"
      ><ng-container *ngTemplateOutlet="tc.template; context: tc.context"></ng-container
    ></mat-chip>
  }

  <button mat-flat-button type="button" (click)="openFilterDrawer()">
    <mat-icon svgIcon="filter-alt" />
    Open filters
  </button>
</mat-chip-set>
