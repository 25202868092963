import { Component, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { AuthService } from "@shared/services";

@Component({
  selector: "ath-sign-in-failed",
  templateUrl: "./sign-in-failed.component.html",
  styleUrl: "./sign-in-failed.component.scss",
  imports: [MatButtonModule],
})
export class SignInFailedComponent {
  private authService = inject(AuthService);

  login(): void {
    this.authService.login();
  }
}
