import { Component, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { AuthService } from "@shared/services";

@Component({
  selector: "ath-signed-out-component",
  templateUrl: "./signed-out.component.html",
  styleUrl: "./signed-out.component.scss",
  imports: [MatButtonModule],
})
export class SignedOutComponent {
  private authService = inject(AuthService);

  login(): void {
    this.authService.login();
  }
}
