import { Component, computed, inject } from "@angular/core";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgTemplateOutlet } from "@angular/common";
import { PillTemplateContext } from "../../activity-filter/models";
import { GridService, GridState } from "../services";

@Component({
  selector: "ath-activity-header",
  templateUrl: "./activity-header.component.html",
  styleUrls: ["./activity-header.component.scss"],
  imports: [MatChipsModule, MatIconModule, MatButtonModule, MatTooltipModule, NgTemplateOutlet],
})
export class ActivityHeaderComponent {
  private gridService = inject(GridService);

  readonly recordsCollection = this.gridService.records;
  readonly templateContexts = this.gridService.templateContexts;

  // Note: Loading state is most likely very brief. Should handle this to prevent flashing text
  searchStatusText = computed(() => {
    const state = this.gridService.gridState();

    if (state === GridState.Data) {
      return `Your search matched ${this.recordsCollection().totalCount} recordings`;
    }

    if (state === GridState.Loading) {
      return "Searching for recordings...";
    }

    return null;
  });

  openFilterDrawer(tc: PillTemplateContext | undefined = undefined): void {
    void this.gridService.filterDrawer?.open().then((result) => {
      if (tc && result === "open") {
        const filterElement = tc.template.elementRef.nativeElement as HTMLElement;
        const filterBase = filterElement.nextElementSibling as HTMLElement;
        filterBase.focus();
        filterBase.nextElementSibling?.scrollIntoView({ behavior: "smooth" });
      }
    });
  }
}
