import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  inject,
  input,
  signal,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { FiltersService } from "../../services";
import { Filter } from "@src/activity/activity-filter/models";
import { NgTemplateOutlet } from "@angular/common";
import { FilterErrorContentDirective } from "./filter-error-content.directive";
import { enterLeaveFadeInOutAnimation } from "@shared/animations";

@Component({
  selector: "ath-filter-base",
  host: {
    "[attr.tabindex]": "-1",
  },
  imports: [MatExpansionModule, MatIconModule, MatButtonModule, NgTemplateOutlet],
  animations: [enterLeaveFadeInOutAnimation],
  templateUrl: "./filter-base.component.html",
  styleUrl: "./filter-base.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBaseComponent {
  private filterService = inject(FiltersService);
  private errorContent = contentChild(FilterErrorContentDirective);

  filter = input.required<Filter>();

  isExpanded = signal(true);
  errorContentTemplateRef = computed(() => this.errorContent()?.templateRef);

  get valid(): boolean {
    return this.filterService.isFilterValid(this.filter().id);
  }

  removeFilter(): void {
    this.filterService.clearFilterValue(this.filter().id);
    this.filterService.toggleFilter(this.filter().id);
  }
}
