<div class="header">
  <h1>Welcome, {{ name }}</h1>
  <span>Let's get started...</span>
</div>

<div class="actions">
  <img
    draggable="false"
    src="/assets/illustrations/empty-filter-state.svg"
    alt="No filters applied"
  />
  <h2>Apply filters to view recordings</h2>
  <button mat-flat-button (click)="openFilterDrawer()">Open filters</button>
</div>
