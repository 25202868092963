/* 
    SVG Icons should be downloaded from https://fonts.google.com/icons?icon.style=Rounded and placed in the assets/icons folder.
    - Size: 24, Weight: 300
    - Width / height should be set to 24px.
    - Style: Material Symbols (new) | Rounded
    - The 'fill' property in the SVG file should either be removed or set to 'currentColor' to allow for dynamic color changes.    
      This might not work well with solid icons, so it is recommended to use outlined icons for now.
    - Icon names are to be defined in the IconNames array.
    - The icon name should be the same as the SVG file name and follow the naming convention as seen in Google Fonts. i.e Keyboard Arrow Up -> keyboard-arrow-up | (kebab-case)
*/

export const IconNames = [
  "add",
  "border-color",
  "calendar-month",
  "call",
  "call-log",
  "chat",
  "close",
  "delete",
  "double-arrow",
  "drag-indicator",
  "filter-alt",
  "help",
  "home",
  "keyboard-arrow-down",
  "keyboard-arrow-up",
  "logout",
  "more-horiz",
  "more-vert",
  "no-sound",
  "pause",
  "person",
  "play-arrow",
  "receipt-long",
  "schedule",
  "search",
  "settings",
  "visibility",
  "visibility-off",
  "volume-down",
  "volume-mute",
  "volume-up",
  "warning",
  "info",
] as const;

export type IconName = (typeof IconNames)[number];
