import { Component, output, input } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerInputEvent, MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";

export const MIN_SUPPORTED_DATE = new Date("2000-01-01");

@Component({
  selector: "ath-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
})
export class DatePickerComponent {
  label = input("Date");
  control = input(new FormControl<Date | null>(null));
  maxDate = input<Date | null>(null);
  minDate = input<Date | null>(MIN_SUPPORTED_DATE);
  dateChange = output<Date | null>();

  onDateChange(event: MatDatepickerInputEvent<Date | null>): void {
    const date = event.value;
    this.control().setValue(date);
    this.dateChange.emit(date);
  }
}
