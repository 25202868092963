import { ChangeDetectionStrategy, Component, input, output, inject } from "@angular/core";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Column } from "@src/activity/models";
import { ActivityGridOptionsDialogComponent } from "./activity-grid-options-dialog/activity-grid-options-dialog.component";

@Component({
  selector: "ath-activity-grid-options",
  imports: [MatDialogModule, MatButtonModule, MatIconModule, MatTooltipModule],
  templateUrl: "./activity-grid-options.component.html",
  styleUrl: "./activity-grid-options.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityGridOptionsComponent {
  readonly dialog = inject(MatDialog);

  readonly columns = input.required<Column[]>();
  readonly columnsChange = output<Column[]>();

  openDialog(): void {
    const dialogRef = this.dialog.open<ActivityGridOptionsDialogComponent, Column[]>(
      ActivityGridOptionsDialogComponent,
      {
        data: this.columns(),
      }
    );

    dialogRef.afterClosed().subscribe((newColumns: Column[] | undefined) => {
      if (newColumns === undefined) {
        return;
      }
      this.columnsChange.emit(newColumns);
    });
  }
}
