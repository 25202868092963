<mat-form-field>
  <mat-label>{{ label() }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    (dateChange)="onDateChange($event)"
    [formControl]="control()"
    [max]="maxDate()"
    [min]="minDate()"
  />
  <mat-datepicker-toggle matIconSuffix [for]="picker">
    <mat-icon matDatepickerToggleIcon svgIcon="calendar-month" matTooltip="Select date" />
  </mat-datepicker-toggle>
</mat-form-field>

<mat-datepicker #picker></mat-datepicker>
