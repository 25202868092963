@let isValid = valid;
@let errorTemplateRef = errorContentTemplateRef();

<mat-expansion-panel
  [expanded]="isExpanded()"
  (closed)="isExpanded.set(false)"
  (opened)="isExpanded.set(true)"
>
  <mat-expansion-panel-header>
    <mat-panel-title [class.error]="!isValid">
      {{ filter().name }}
      @if (!isValid) {
        <mat-icon [@enterLeaveFadeInOutAnimation] svgIcon="warning" />
      }
    </mat-panel-title>

    @if (!filter().required) {
      <button mat-icon-button (click)="removeFilter()">
        <mat-icon svgIcon="delete" />
      </button>
    }
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <ng-content />

    @if (errorTemplateRef && !isValid) {
      <div class="error-info-column" [@enterLeaveFadeInOutAnimation]>
        <ng-container [ngTemplateOutlet]="errorTemplateRef" />
      </div>
    }
  </ng-template>
</mat-expansion-panel>
