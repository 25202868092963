import { HttpErrorResponse, HttpEvent, HttpInterceptorFn } from "@angular/common/http";
import { catchError, Observable, of } from "rxjs";

export const httpErrorLoggingInterceptor: HttpInterceptorFn = (
  req,
  next
): Observable<HttpEvent<unknown>> => {
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMessage: string | null = null;
      switch (error.status) {
        case 400:
          errorMessage = "Bad Request: The server could not understand the request.";
          break;
        case 401:
          errorMessage = "Unauthorized: Access is denied due to invalid credentials.";
          break;
        case 403:
          errorMessage = "Forbidden: You do not have permission to access this resource.";
          break;
        case 404:
          errorMessage = "Not Found: The requested resource could not be found.";
          break;
        case 500:
          errorMessage = "Internal Server Error: An unexpected error occurred on the server.";
          break;
        case 502:
          errorMessage = "Bad Gateway: The server received an invalid response from the server.";
          break;
        case 503:
          errorMessage =
            "Service Unavailable: The server is currently unable to handle the request.";
          break;
        case 504:
          errorMessage =
            "Gateway Timeout: The server did not receive a timely response from the server.";
          break;
        default:
          break;
      }

      //Once we have a logger service we can send the error to the logger service here instead of logging it to the console
      //This will only handle http errors and not other types of errors
      //Other errors are designed to get caught by the global error handler and be handled there

      if (errorMessage) {
        console.error(errorMessage, error);
        return of();
      }

      //This will propegate any unkown errors to the global error handler if the error is not caught.
      throw error.error;
    })
  );
};
