<ng-template #pillTemplate let-details="details">
  <span>{{ details }}</span>
</ng-template>

<ath-filter-base [filter]="filter()">
  <ath-dropdown-multiselect
    [placeholder]="placeholderText()"
    [items]="pristineSources()"
    (selectionUpdated)="updateFilter($event)"
  />
</ath-filter-base>
