import { Component, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { GridService } from "../services";
import { enterLeaveFadeInOutAnimation } from "@shared/animations";

@Component({
  selector: "ath-activity-grid-no-results",
  imports: [MatButtonModule],
  templateUrl: "./activity-grid-no-results.component.html",
  styleUrl: "./activity-grid-no-results.component.scss",
  host: {
    "[@enterLeaveFadeInOutAnimation]": "true",
  },
  animations: [enterLeaveFadeInOutAnimation],
})
export class ActivityGridNoResultsComponent {
  private gridService = inject(GridService);

  openFilterDrawer(): void {
    void this.gridService.filterDrawer?.open();
  }
}
