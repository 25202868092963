import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { RowOptions } from "@shared/models";

@Component({
  selector: "ath-row",
  imports: [CommonModule],
  templateUrl: "./row.component.html",
  styleUrl: "./row.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowComponent {
  private defaults: RowOptions = {
    gap: "16px",
    wrap: "wrap",
    justify: "flex-start",
    align: "stretch",
    padding: "16px",
  };

  @Input() options?: RowOptions;

  @HostBinding("style.gap")
  get gapStyle(): string {
    return this.options?.gap ?? this.defaults.gap!;
  }

  @HostBinding("style.flex-wrap")
  get wrapStyle(): string {
    return this.options?.wrap ?? this.defaults.wrap!;
  }

  @HostBinding("style.justify-content")
  get justifyStyle(): string {
    return this.options?.justify ?? this.defaults.justify!;
  }

  @HostBinding("style.align-items")
  get alignStyle(): string {
    return this.options?.align ?? this.defaults.align!;
  }

  @HostBinding("style.padding")
  get paddingStyle(): string {
    return this.options?.padding ?? this.defaults.padding!;
  }
}
