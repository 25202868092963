import { DetailedRecording, Interval, Recording, RecordingKinds } from "@shared/models";
import { RecordingResponse, RecordingsResponse } from "@src/app/activity/activity-grid/models";
import { intervalToDuration } from "date-fns";

export const mapRecording = (recording: RecordingsResponse): Recording => {
  const interval = mapInterval(recording.interval);
  const audioDuration =
    recording.kind === RecordingKinds.CallRecording ? formatDuration(interval) : undefined;

  return {
    ...recording,
    interval: interval,
    duration: audioDuration,
  };
};

export const mapDetailedRecording = (recording: RecordingResponse): DetailedRecording => {
  return {
    ...recording,
    interval: mapInterval(recording.interval),
  };
};

export function mapInterval(interval: string): Interval {
  const [startDateString, endDateString] = interval.split("/");
  const start = new Date(startDateString);
  const end = new Date(endDateString);

  return { start, end };
}

function formatDuration(interval: Interval): string {
  const { hours, minutes, seconds } = intervalToDuration(interval);

  return `${hours?.toString().padStart(2, "0") ?? "00"}:${
    minutes?.toString().padStart(2, "0") ?? "00"
  }:${seconds?.toString().padStart(2, "0") ?? "00"}`;
}
