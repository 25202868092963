import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  input,
  OnInit,
  signal,
  TemplateRef,
  viewChild,
} from "@angular/core";
import { DropdownMultiselectItem, Source } from "@shared/models";
import { DropdownMultiselectComponent } from "@shared/components/";
import { FilterValueService } from "@src/activity/activity-filter/services/filter-value.service";
import { Filter, SourceFilter, PillTemplateContext } from "../../models";
import { FilterBaseHandler, FilterBaseComponent, FilterBase } from "../";

@Component({
  selector: "ath-source-filter",
  imports: [DropdownMultiselectComponent, FilterBaseComponent],
  templateUrl: "./source-filter.component.html",
  styleUrl: "./source-filter.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceFilterComponent extends FilterBase implements OnInit {
  filter = input.required<Filter>();

  private filterHandler: FilterBaseHandler<SourceFilter>;
  private selectedSources = signal<DropdownMultiselectItem[]>([]);
  private pillTemplate = viewChild.required<TemplateRef<unknown>>("pillTemplate");
  private dropdownMultiselect = viewChild.required<DropdownMultiselectComponent>(
    DropdownMultiselectComponent
  );

  pristineSources = signal<DropdownMultiselectItem[]>([]);

  placeholderText = computed(() =>
    this.pristineSources().length === 0 ? "No sources available" : "All available sources"
  );

  constructor(
    private filterValueService: FilterValueService<Source>,
    private destroyRef: DestroyRef
  ) {
    super();

    this.filterHandler = new FilterBaseHandler<SourceFilter>(
      () => true,
      () => ({
        key: "SourceGroupIds",
        values: this.selectedSources().map((x) => x.id),
      }),
      () => this.createTemplateContext()
    );

    const clearSub = this.filterHandler.filterCleared$.subscribe((initial) => {
      const initialSources = this.mapSourcesToDropdownItems([...initial.sources.values()]);
      this.dropdownMultiselect().clearSelection();
      this.selectedSources.set(initialSources);
    });

    this.destroyRef.onDestroy(() => {
      clearSub.unsubscribe();
    });
  }

  private mapSourcesToDropdownItems(sources: Source[]): DropdownMultiselectItem[] {
    return sources.map((source) => ({
      id: source.id,
      value: source.name,
    }));
  }

  private createTemplateContext(): PillTemplateContext {
    const context = {
      details: this.selectedSources().length
        ? `Sources (${this.selectedSources().length})`
        : "All sources",
    };

    return {
      template: this.pillTemplate(),
      context,
    };
  }

  ngOnInit(): void {
    this.filterValueService.getFilterValues(this.filter().valueEndpoint!).subscribe((sources) => {
      this.pristineSources.set(this.mapSourcesToDropdownItems(sources));
      this.filterHandler.initialize(
        {
          sources: new Set(),
        },
        this.filter()
      );
    });
  }

  updateFilter(selectedSources: DropdownMultiselectItem[]): void {
    this.selectedSources.set(selectedSources);

    this.filterHandler.updateState({
      sources: new Set(selectedSources.map((x) => ({ id: x.id, name: x.value }))),
    });
  }
}
