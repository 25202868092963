@let label = title();

<mat-form-field class="dropdown">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <mat-select
    [placeholder]="placeholder()"
    [formControl]="selectedItems"
    (openedChange)="onDropdownToggled()"
    (selectionChange)="onSelectionChange($event.value)"
    multiple
  >
    <mat-select-trigger>
      {{ firstSelectedItemName }}
      @if ((selectedItemsCount || 0) > 1) {
        <span class="multiple-selection">
          {{ multipleSelectionText }}
        </span>
      }
    </mat-select-trigger>
    @for (item of items(); track item.id) {
      <mat-option [value]="item">{{ item.value }}</mat-option>
    }
  </mat-select>
</mat-form-field>
