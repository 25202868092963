import { Injectable, Signal, TemplateRef } from "@angular/core";
import { format } from "date-fns";
import { TimePickerChangeEvent } from "@shared/components";
import { DateService } from "@shared/services";
import { PillTemplateContext } from "../../models";

@Injectable()
export class DateFilterService {
  constructor(private dateService: DateService) {}

  get defaultStartDate(): Date {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  get defaultEndDate(): Date {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date;
  }

  formatUTCInterval(start: Date, end: Date): string {
    const _start = this.dateService.convertTzToUTC(start);
    const _end = this.dateService.convertTzToUTC(end);

    return `${_start.toISOString()}/${_end.toISOString()}`;
  }

  setDateTime(date: Date, time: TimePickerChangeEvent): void {
    date.setHours(time.hour);
    date.setMinutes(time.minute);
  }

  createTemplateContext(
    start: Date | null,
    end: Date | null,
    pillTemplate: Signal<TemplateRef<unknown>>
  ): PillTemplateContext | undefined {
    if (!start || !end) {
      return undefined;
    }

    const context = {
      details: {
        start: {
          date: format(start, "dd LLL yyyy"),
          time: format(start, "HH:mm"),
        },
        end: {
          date: format(end, "dd LLL yyyy"),
          time: format(end, "HH:mm"),
        },
      },
    };

    return context
      ? {
          template: pillTemplate(),
          context,
        }
      : undefined;
  }
}
