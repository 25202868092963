@let _isExpanded = isExpanded();

<nav #nav class="nav">
  @if (!_isExpanded) {
    <img class="logo" src="assets/logos/touch_t_only.svg" alt="Touch logo" />
  } @else {
    <img class="logo-expanded" src="assets/logos/touch_full.svg" alt="Touch logo" />
  }

  <div id="upper-links" class="nav-content">
    @for (item of navItems; track item.name) {
      <a
        class="nav-link"
        [routerLink]="item.route"
        routerLinkActive="nav-active"
        [routerLinkActiveOptions]="activeOptions"
        [ariaLabel]="item.name"
        [matTooltip]="item.name"
        matTooltipPosition="right"
        [matTooltipDisabled]="_isExpanded"
      >
        <mat-icon [svgIcon]="item.icon" />
        @if (_isExpanded) {
          <span>{{ item.name }}</span>
        }
      </a>
    }
  </div>

  @if (navItems2.length) {
    <span class="divider"></span>
  }

  <div class="nav-content">
    @for (item of navItems2; track item.name) {
      <a
        class="nav-link"
        [routerLink]="item.route"
        routerLinkActive="nav-active"
        [routerLinkActiveOptions]="activeOptions"
        [ariaLabel]="item.name"
        [matTooltip]="item.name"
        matTooltipPosition="right"
        [matTooltipDisabled]="_isExpanded"
      >
        <mat-icon [svgIcon]="item.icon" />
        @if (_isExpanded) {
          <span>{{ item.name }}</span>
        }
      </a>
    }
  </div>

  <div class="nav-footer">
    <div class="nav-expand" [class.expanded]="_isExpanded">
      <button
        mat-icon-button
        type="button"
        aria-label="Expand sidebar"
        (click)="toggleExpand()"
        [matTooltip]="_isExpanded ? 'Collapse' : 'Expand sidebar'"
        matTooltipPosition="right"
      >
        <mat-icon svgIcon="double-arrow" />
      </button>
    </div>

    <div
      class="nav-logout"
      matTooltip="Sign out"
      [matTooltipDisabled]="_isExpanded"
      matTooltipPosition="right"
    >
      <button mat-icon-button type="button" aria-label="Sign out" (click)="initiateLogout()">
        <mat-icon svgIcon="logout" />
        @if (_isExpanded) {
          <span style="white-space: nowrap">Sign out</span>
        }
      </button>
    </div>
  </div>
</nav>
