import { ErrorHandler, Injectable } from "@angular/core";

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  handleError(error: Error): void {
    //TODO: Implement error logging to centralized service [LINK TASK HERE]
    //Errors should be sent to the logging service from this handler
    //This ensures that both errors from either the server and the client can be logged
    //Need to assure that in the future whenever an error is caught earlier in the chain that the error gets rethrown unless its is an error that is expected
    console.error(error);
  }
}
