@let filters = selectedFilters();

<div class="header">
  <div>
    <h1>Filters</h1>
    <p>Use filters to narrow search results</p>
  </div>
  <button mat-icon-button (click)="closeDrawer()" aria-label="Close filter panel" type="button">
    <mat-icon svgIcon="close" />
  </button>
</div>

<div class="filters">
  @for (filter of filters; track filter.id) {
    <ng-container *ngComponentOutlet="filter.component; inputs: { filter: filter }" />
  }
</div>

@if (inactiveFilters().length) {
  <button
    @enterLeaveFadeInOutAnimation
    mat-fab
    extended
    [matMenuTriggerFor]="filterSelectorMenu"
    class="fab-btn"
    type="button"
  >
    <mat-icon svgIcon="add" />
    Filter
  </button>
}

<div class="footer">
  <button mat-button (click)="clearAllFilters()" [disabled]="filters.length === 0">
    Reset filters
  </button>
  <button
    class="apply-filters-btn"
    mat-flat-button
    [disabled]="!canApplyFilters()"
    (click)="applyFilters()"
    type="button"
  >
    Apply filters
  </button>
</div>

<mat-menu #filterSelectorMenu="matMenu" yPosition="above">
  <ng-template matMenuContent>
    @for (filter of inactiveFilters(); track filter.id) {
      <button mat-menu-item (click)="activateFilter(filter, $event)" type="button">
        {{ filter.name }}
      </button>
    } @empty {
      <span mat-menu-item disabled>All filters active</span>
    }
  </ng-template>
</mat-menu>
