import { computed, effect, Injectable, signal } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import WaveSurfer from "wavesurfer.js";
import Hover from "wavesurfer.js/dist/plugins/hover.js";
import { IconName } from "@shared/constants";

enum PlaybackState {
  NotStarted = 0,
  Playing = 1,
  Paused = 2,
}

@Injectable()
export class AudioService {
  private playbackState = signal(PlaybackState.NotStarted);
  private wavesurfer?: WaveSurfer;
  private readonly audio = new Audio();
  private prevVolume = signal(1);

  ready = signal(false);
  duration = signal(0);
  currentTime$ = new BehaviorSubject(0);
  volume = signal(1);

  volumeIcon = computed<IconName>(() => {
    const volume = this.volume();

    if (volume === 0) {
      return "no-sound";
    } else if (volume < 0.5) {
      return "volume-down";
    } else {
      return "volume-up";
    }
  });

  playbackIcon = computed((): IconName => {
    const state = this.playbackState();
    if (state === PlaybackState.Playing) {
      return "pause";
    } else {
      return "play-arrow";
    }
  });

  constructor() {
    effect(() => {
      const volume = this.volume();
      this.wavesurfer?.setVolume(volume);
    });
  }

  load(audioUrl: string, container: HTMLElement): void {
    this.audio.src = audioUrl;

    this.wavesurfer = WaveSurfer.create({
      container: container,
      waveColor: "gainsboro",
      progressColor: "rgb(0, 108, 81)",
      cursorColor: "transparent",
      media: this.audio,
      dragToSeek: true,
      barWidth: 6,
      barGap: 4,
      barRadius: 8,
      backend: "WebAudio",
      plugins: [
        Hover.create({
          lineColor: "rgb(0, 108, 81)",
          lineWidth: 2,
          labelBackground: "rgb(87, 95, 102)",
          labelColor: "rgb(245, 245, 245)",
          labelSize: "11px",
        }),
      ],
    });

    // Loading progression
    // this.wavesurfer.on("loading", (progress) => {
    // });

    this.wavesurfer.on("play", () => {
      if (this.playbackState() !== PlaybackState.Playing) {
        this.playbackState.set(PlaybackState.Playing);
      }
    });

    this.wavesurfer.on("pause", () => {
      if (this.playbackState() === PlaybackState.Playing) {
        this.playbackState.set(PlaybackState.Paused);
      }
    });

    this.wavesurfer.on("timeupdate", (currentTime) => this.currentTime$.next(currentTime));

    this.wavesurfer.once("ready", () => {
      this.ready.set(true);
      this.duration.set(this.wavesurfer?.getDuration() ?? 0);
    });
  }

  togglePlayback(): void {
    if (this.playbackState() === PlaybackState.Playing) {
      void this.wavesurfer?.pause();
    } else {
      void this.wavesurfer?.play();
    }
  }

  toggleMute(): void {
    if (this.volume() === 0) {
      this.setVolume(this.prevVolume());
    } else {
      this.setVolume(0);
    }
  }

  seek(seekTo: number): void {
    this.wavesurfer?.setTime(this.wavesurfer?.getCurrentTime() + seekTo);
  }

  setPlaybackRate(rate: number): void {
    this.wavesurfer?.setPlaybackRate(rate);
  }

  setVolume(volume: number): void {
    this.prevVolume.set(this.volume());
    this.volume.set(volume);
  }

  handleHotkeys(e: KeyboardEvent): boolean {
    let handled = true;

    switch (e.code) {
      case "Space":
        this.togglePlayback();
        break;

      case "KeyM":
        this.toggleMute();
        break;

      case "ArrowRight":
        this.seek(5);
        break;

      case "ArrowLeft":
        this.seek(-5);
        break;

      case "ArrowUp":
        this.setVolume(Math.round((this.wavesurfer?.getVolume() ?? 0 + 0.05) * 20) / 20);
        break;

      case "ArrowDown":
        this.setVolume(Math.round((this.wavesurfer?.getVolume() ?? 1 - 0.05) * 20) / 20);
        break;

      default:
        handled = false;
        break;
    }

    return handled;
  }

  destroy(): void {
    this.wavesurfer?.destroy();
  }
}
