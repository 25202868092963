import {
  Component,
  HostBinding,
  HostListener,
  inject,
  output,
  signal,
  AfterViewInit,
  model,
  OnInit,
} from "@angular/core";
import { panelTransitionAnimation } from "@shared/animations/";
import { AnimationEvent } from "@angular/animations";
import { NonNullableFormBuilder, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "ath-time-picker-details",
  imports: [ReactiveFormsModule, MatButtonModule],
  templateUrl: "./time-picker-details.component.html",
  styleUrl: "./time-picker-details.component.scss",
  animations: [panelTransitionAnimation],
})
export class TimePickerDetailsComponent implements OnInit, AfterViewInit {
  private readonly hourLimit = 24;
  private readonly minuteLimit = 60;
  private readonly inputLength = 2;

  protected fb = inject(NonNullableFormBuilder);
  protected closeButtonHidden = signal(true);
  protected timeForm = this.fb.group({
    hour: this.fb.control(""),
    minute: this.fb.control(""),
  });

  protected closed = output<void>();

  hour = model.required<number>();
  minute = model.required<number>();

  @HostBinding("@panelTransitionAnimation") protected animationState = "hidden";
  @HostListener("@panelTransitionAnimation.done", ["$event"]) protected onAnimationDone(
    event: AnimationEvent
  ): void {
    if (event.toState === "hidden") {
      this.closed.emit();
    }
  }

  ngOnInit(): void {
    this.timeForm.controls.hour.setValue(this.padNumber(this.hour()));
    this.timeForm.controls.minute.setValue(this.padNumber(this.minute()));
  }

  ngAfterViewInit(): void {
    this.animationState = "visible";
  }

  close(): void {
    this.animationState = "hidden";
  }

  protected setCloseButtonVisibility(val: boolean): void {
    this.closeButtonHidden.set(val);
  }

  protected selectField(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.select();
  }

  protected adjustTime(event: WheelEvent, type: "hour" | "minute"): void {
    const isUp = event.deltaY < 0;
    switch (type) {
      case "hour":
        this.updateHour((this.hour() + (isUp ? 1 : -1) + this.hourLimit) % this.hourLimit);
        break;
      case "minute":
        if (this.minute() === this.minuteLimit - 1 && isUp) {
          this.updateHour((this.hour() + 1 + this.hourLimit) % this.hourLimit);
        } else if (this.minute() === 0 && !isUp) {
          this.updateHour((this.hour() - 1 + this.hourLimit) % this.hourLimit);
        }

        this.updateMinute((this.minute() + (isUp ? 1 : -1) + this.minuteLimit) % this.minuteLimit);
        break;
    }
  }

  protected updateTimeValues(): void {
    this.updateHour(parseInt(this.timeForm.controls.hour.value.slice(-this.inputLength)));
    this.updateMinute(parseInt(this.timeForm.controls.minute.value.slice(-this.inputLength)));
  }

  private updateHour(value: number): void {
    if (!value) {
      value = 0;
    }

    if (value >= this.hourLimit) {
      value = this.hourLimit - 1;
    }

    this.timeForm.controls.hour.setValue(this.padNumber(value));
    this.hour.set(value);
  }

  private updateMinute(value: number): void {
    if (!value) {
      value = 0;
    }

    if (value >= this.minuteLimit) {
      value = this.minuteLimit - 1;
    }

    this.timeForm.controls.minute.setValue(this.padNumber(value));
    this.minute.set(value);
  }

  private padNumber(value: number): string {
    return value.toString().padStart(2, "0");
  }
}
