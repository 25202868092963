import { ChangeDetectionStrategy, Component, input, output } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { IconName } from "@shared/constants";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "ath-icon-button",
  imports: [MatButtonModule, MatIconModule, MatTooltipModule],
  templateUrl: "./icon-button.component.html",
  styleUrl: "./icon-button.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  icon = input.required<IconName>();
  scale = input<"large">();
  tooltip = input<string>("");
  disabled = input<boolean>(false);
  athClick = output<void>();

  onClick(event: MouseEvent): void {
    event.stopPropagation();
    this.athClick.emit();
  }
}
