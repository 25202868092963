<ng-template #pillTemplate let-details="details">
  <span>{{ details }}</span>
</ng-template>

@let selection = selectedAccounts();

<ath-filter-base [filter]="filter()">
  <div class="content">
    @if (selection.size) {
      <ath-logical-operator-toggle
        [vertical]="true"
        (valueChange)="onLogicalOperatorChange($event)"
      />
    }

    <mat-form-field class="flex-1">
      <mat-chip-grid #chipGrid aria-label="Participant selection">
        @for (account of selection; track account.id) {
          <mat-chip-row (removed)="removeAccount(account)">
            {{ account.value }}

            <button matChipRemove [attr.aria-label]="'remove ' + account.value">
              <mat-icon svgIcon="delete" />
            </button>
          </mat-chip-row>
        } @empty {
          <mat-chip highlighted>Add participants to activate filter.</mat-chip>
        }
      </mat-chip-grid>
      <input
        placeholder="Click and type to find participants"
        [formControl]="accountQuery"
        [matChipInputFor]="chipGrid"
        [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="keyCodes"
        #inputField
      />

      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="addAccount($event)"
        hideSingleSelectionIndicator
      >
        @for (account of filteredAccounts$ | async; track account.id) {
          <mat-option [value]="account">{{ account.value }}</mat-option>
        } @empty {
          <mat-option disabled>No participants found</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </div>
</ath-filter-base>
